import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import _ from 'lodash';
import BlogLayout from '../components/BlogLayout'
import { HTMLContent } from '../components/Content'

import BlogPostTemplate from '../components/BlogPostTemplate';

const BlogPost = ({ data }) => {
  const {
    markdownRemark: post,
    allMarkdownRemark,
  } = data
  const {
    frontmatter,
  } = post;
  // build articles from query
  const articles = _.castArray(_.get(allMarkdownRemark, 'edges') || []).map(edge => {
    const article = _.get(edge, 'node');
    return {
      name: _.get(article, 'frontmatter.title'),
      url: _.get(article, 'fields.slug'),
      thumbnail_url: _.get(article, 'frontmatter.image.childImageSharp.fluid.src'),
      isLiked: true,
      isBookmarked: true,
      articleId: 1,
      likeCount: 10,
      bookmarkCount: 10,
      post: article,
    };
  });
  return (
    <BlogLayout >
      <BlogPostTemplate
        post={post}
        content={post.html}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        tags={frontmatter.tags}
        helmet={
          <Helmet
            titleTemplate="%s | Blog"
          >
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
          </Helmet>
        }
        title={frontmatter.title}
        image={get(frontmatter.image, 'childImageSharp.fluid.src', frontmatter.image)}
        articles={articles}
      />
    </BlogLayout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
        category
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 8
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
            category
          }
        }
      }
    }
  }
`
